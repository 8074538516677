var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('onboarding-questions')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }