<template>
  <v-row>
    <v-col>
      <onboarding-questions></onboarding-questions>
    </v-col>
  </v-row>
</template>

<script>
import OnboardingQuestions from "@/view/components/Common/OnboardingQuestions";
export default {
  components: { OnboardingQuestions },
};
</script>

<style scoped></style>
